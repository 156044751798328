"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("../css/app.scss");
document.addEventListener('DOMContentLoaded', () => {
    var _a;
    const input = document.querySelector('#login-input');
    const alertEl = document.querySelector('#login-alert');
    input.addEventListener('change', () => {
        input.value = input.value.trim().toLowerCase();
    });
    const url = new URL(window.location.href);
    const error = url.searchParams.get('error');
    const message = url.searchParams.get('message');
    url.searchParams.delete('error');
    url.searchParams.delete('message');
    window.history.replaceState(null, '', url);
    alertEl.classList.toggle('alert-danger', !!error);
    alertEl.classList.toggle('alert-info', !!message);
    alertEl.classList.toggle('d-none', !(error || message));
    if (error) {
        alertEl.innerHTML = (_a = {
            WRONG_LOGIN_OR_PASSWORD: 'identifiants incorrects',
            IP_NOT_ALLOWED: 'Ip non autorisée',
            MISSING_LOGIN: 'Identifiant manquant',
            MISSING_PASSWORD: 'Mot de passe manquant'
        }[error]) !== null && _a !== void 0 ? _a : error;
    }
    if (message) {
        alertEl.innerHTML = message;
    }
});
